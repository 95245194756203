/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import PageHasNoEngagementURLParametersException
  from '~/src/Domain/Engagement/Exception/PageHasNoEngagementURLParametersException'
import PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import { PhaseType } from '~/src/Domain/Engagement/PhaseType'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useEngagementState from '~/src/UserInterface/Engagement/composables/useEngagementState'
import useOrganisationState from '~/src/UserInterface/Organisation/composables/useOrganisationState'

/**
 * This middleware must be called on pages with the dynamic '/:organisationId/:organisationSlug/:engagementId/:engagementSlug/*' path
 *
 * Prerequisites for this middleware:
 * - The user must be authenticated as a regular user
 * - The organisation state must be populated
 * - The engagements state must be populated
 *
 * This middleware checks the active engagement:
 * - If the current page does not have the 'engagementId' and 'engagementSlug' URL params, an exception is thrown
 * - If there is an active engagement in state, and the engagement from URL parameters is the same as currently in state, the process continues
 * - If there is no active engagement in state, or the engagement from URL parameters is different as currently in state, the engagement is searched for in state:
 *   - If the engagement exists in state, activate the engagement
 *   - If the engagement does not exist in state, redirect the user to the organisation settings page with a list of engagments
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useService('logger')
  logger.info('[ENGAGEMENT] Middleware fired')

  if (typeof to.params.engagementId !== 'string') {
    throw new PageHasNoEngagementURLParametersException(
      `Current page "${to.fullPath}" has no engagementId in URL params.`,
    )
  }

  const nuxtApp = useNuxtApp()
  const uuidEncoder = useService('uuidEncoder')
  const { getCurrentEngagement, hasCurrentEngagement, getCurrentPhase, hasCurrentPhase, engagements, activate } = useEngagementState()
  const decodedEngagementId = uuidEncoder.decode(to.params.engagementId)

  const currentEngagementIsRequested = hasCurrentEngagement.value ? getCurrentEngagement().value['@id'].toId() === decodedEngagementId : false
  const currentPhaseIsRequested
    = typeof to.params.phaseId !== 'string' ? true : hasCurrentPhase.value ? getCurrentPhase().value['@id'].toId() === uuidEncoder.decode(to.params.phaseId) : false

  if (
    currentEngagementIsRequested && currentPhaseIsRequested
  ) {
    logger.info('[ENGAGEMENT] Requested engagement and phase are equal to current engagement and phase.')

    return
  } else if (!currentEngagementIsRequested) {
    logger.info('[ENGAGEMENT] User has requested a different engagement.')
  } else if (!currentPhaseIsRequested) {
    logger.info('[ENGAGEMENT] User has requested a different phase.')
  }

  const engagementIri = EngagementIri.fromId(decodedEngagementId)
  const foundEngagement = engagements.value.find((e) => e['@id'].equalsTo(engagementIri))
  if (foundEngagement === undefined) {
    logger.info(
      `[ENGAGEMENT] Requested engagement '${engagementIri.toString()}' does not exist in state.`,
    )
  } else {
    let phaseIri: PhaseIri | undefined
    if (typeof to.params.phaseId === 'string') {
      phaseIri = foundEngagement.phases.find((p) => p['@id'].equalsTo(PhaseIri.fromId(engagementIri.toId(), uuidEncoder.decode(to.params.phaseId as string))))?.['@id']
    } else {
      phaseIri = foundEngagement.phases.find((p) => p.type === PhaseType.YEAR_END)?.['@id']
    }

    if (phaseIri !== undefined) {
      await activate(engagementIri, phaseIri)

      logger.info('[ENGAGEMENT] Engagement activated.')

      return
    }
  }

  const { getOrganisation } = useOrganisationState()
  const organisation = getOrganisation()

  const organisationId = uuidEncoder.encode(
    organisation.value['@id'].toId(),
  )
  const organisationSlug = organisation.value.getSlug().toString()

  logger.info('[ENGAGEMENT] Redirecting to engagement list.')

  return navigateTo(
    // eslint-disable-next-line ts/no-unsafe-argument
    nuxtApp.$localePath({
      name: 'settings-organisation-Engagement',
      params: {
        organisationId,
        organisationSlug,
      },
    }),
  )
})
